<template>
  <MDBBtn
    color="light"
    tag="a"
  >
    <MDBIcon
      class="me-2"
      :icon="icon"
    />
    <slot />
  </MDBBtn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
  },
}
</script>
