<template>
  <MDBDropdown v-model="dropdown">
    <MDBDropdownToggle
      size="lg"
      :color="color"
      @click="dropdown = !dropdown"
    >
      <MDBIcon
        class="me-2"
        :icon="icon"
        :icon-style="iconStyle"
      />
      {{ title }}
    </MDBDropdownToggle>

    <MDBDropdownMenu>
      <slot />
    </MDBDropdownMenu>
  </MDBDropdown>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    color: {
      type: String,
      default: 'light'
    },
    icon: {
      type: String,
      default: null,
    },
    iconStyle: {
      type: String,
      default: 'fas',
    },
    title: {
      type: String,
      default: null,
    },
  },

  setup() {
    const dropdown = ref(false)

    return {
      dropdown
    }
  },
}
</script>
