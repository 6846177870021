<template>
  <canvas id="background" />
</template>

<script>
import '@/assets/js/background.js'

export default {
  name: 'Background',
}
</script>

<style scoped>
canvas {
  display: block;
  left: 0;
  position: fixed;
  top: 0;
}
</style>