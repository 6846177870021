<template>
  <MDBDropdownItem>
    <MDBIcon
      class="me-2"
      :icon="icon"
      :icon-style="iconStyle"
    />
    <slot />
  </MDBDropdownItem>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconStyle: {
      type: String,
      default: 'fas',
    },
  },
}
</script>

<style scoped>
.dropdown-menu li i {
  min-width: 14px;
  text-align: center;
}
</style>
