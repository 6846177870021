<template>
  <router-link
    v-slot="{ href }"
    custom
  >
    <Button
      :href="href"
      :icon="icon"
      v-bind="$attrs"
    >
      <slot />
    </Button>
  </router-link>
</template>

<script>
import Button from './Button.vue'

export default {
  components: {
    Button
  },

  props: {
    icon: {
      type: String,
      default: null,
    },
  },
}
</script>
