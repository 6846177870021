<template>
  <div
    id="buttons"
    class="align-items-center d-flex flex-column flex-lg-row justify-content-center"
  >
    <Dropdown
      icon="android"
      icon-style="fab"
      title="Apps"
    >
      <DropdownItem
        href="https://play.google.com/store/apps/dev?id=6309048418966990566"
        icon="google-play"
        icon-style="fab"
      >
        Google Play
      </DropdownItem>

      <DropdownItem
        disabled
        href="#"
        icon="apple"
        icon-style="fab"
      >
        App Store
      </DropdownItem>
    </Dropdown>

    <Dropdown
      icon="bitcoin"
      icon-style="fab"
      title="Crypto"
    >
      <DropdownItem
        href="https://bitnodes.io/nodes/159.69.6.59-8333/"
        icon="bitcoin"
        icon-style="fab"
        new-tab="true"
      >
        Bitcoin Node #1
      </DropdownItem>

      <DropdownItem
        href="https://amboss.space/es/node/0363674867df44483a3efc296ad0708ac68e542108137140029b40f6b7753583b7"
        icon="bolt"
        icon-style="fas"
        new-tab="true"
      >
        Lightning Network Node #1
      </DropdownItem>
    </Dropdown>
  
    <RouterButton
      icon="user-secret"
      size="lg"
      to="/privacy"
    >
      Privacy Policy
    </RouterButton>

    <RouterButton
      icon="balance-scale"
      size="lg"
      to="/legal"
    >
      Legal Notice
    </RouterButton>

    <Button
      color="primary"
      href="mailto:admin@lowlevel.st"
      icon="at"
      size="lg"
    >
      Contact Us
    </Button>
  </div>
</template>

<script>
import Button       from '../Button.vue'
import Dropdown     from './Dropdown.vue'
import DropdownItem from './DropdownItem.vue'
import RouterButton from '../RouterButton.vue'

export default {
  name: 'ButtonBar',

  components: {
    Button,
    Dropdown,
    DropdownItem,
    RouterButton,
  },
}
</script>

<style lang="scss" scoped>
@import '~@/../mdb/scss/index.free.scss';

#buttons ::v-deep(.btn) {
  margin: 0 5px;

  @include media-breakpoint-down(lg) {
    margin: 5px 0;
    width: 200px;
  }
}
</style>
