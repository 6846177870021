<template>
  <Background />

  <div class="vh-100">
    <div class="align-items-center d-flex flex-wrap h-100 justify-content-center">
      <div class="p-4 position-relative text-center">
        <Header class="mb-4 mb-lg-5" />
        <ButtonBar />
      </div>
    </div>
  </div>
</template>

<script>
import Background from '@/components/home/Background.vue'
import ButtonBar  from '@/components/home/ButtonBar.vue'
import Header     from '@/components/home/Header.vue'

export default {
  components: {
    Background,
    ButtonBar,
    Header,
  },
}
</script>