<template>
  <div class="header">
    <img
      id="logo"
      class="mb-6"
      alt="Lowlevel Studios"
      src="@/assets/logo.svg"
    >

    <h1 class="mb-4 mb-lg-0 slogan">
      Android · iOS · Cloud · Crypto
    </h1>

    <h2 class="slogan">
      This is what we <span class="text-danger">do</span>
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style lang="scss" scoped>
@import '~@/../mdb/scss/index.free.scss';

#logo {
  filter: drop-shadow(0 6px 6px rgba(0, 0, 0, .3));
  max-height: 140px;
  width: auto;
}

.slogan {
  color: white;
  font-size: $h1-font-size;
  font-weight: bolder;
  text-shadow: 0 6px 6px rgb(20 20 20 / 50%);
}

@include media-breakpoint-up(lg) {
  .slogan {
    font-size: $h1-font-size * 1.75;
  }
}
</style>